
/* .qr-image-wrapper section { 
  border-radius: 16px;                   
}

.qr-image-wrapper section div {
  box-shadow: unset !important;     
} */

#html5qr-code-full-region__dashboard_section_swaplink {
    display: none !important;
}